import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { setIsUserAuthenticated, setToken } from "../../redux/reducers/user";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation } from "@reach/router";
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  FormControl,
  Hidden,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "../container.js";
import VisibilityOn from "./icons/VisibilityOn.svg";
import VisibilityOff from "./icons/VisibilityOff.svg";
import ErrorIcon from "./icons/error.svg";
import { getUserObject, decodeEmail } from "../../lib/helpers";
import fullSizeLogin from "./icons/log-in.png";
import ErrorInfo from "./icons/error-info.svg";
import VisibleErrorInfo from "./icons/visible-error-info.svg";
import CampusCTA from "../landingPageComponents/blocks/reuseableCampusComponents/campusCTA"

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      // border: "2px solid #FFD574",
      backgroundColor: "#F7F5E7",
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,

      background: "#F7F5E7",
      "& input": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#4A4A6A",
      },
      "& fieldset": {
        border: "none",
      },
      "&:active fieldset": {
        // background: "#FFFFFF",
      },
      "&:hover fieldset": {
        // background: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        // background: "#FFFFFF",
        boxShadow: "none",
      },
      "&.Mui-error fieldset": {
        color: "#C94277",
      },
    },
  },
})(TextField);

const LoginForm = ({}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    clearErrors,
    setValue,
  } = useForm();

  const useStyles = makeStyles((theme) => ({
    formContainer: {
      paddingTop: "122px",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.up("md")]: {
        // maxWidth: "430px",
        // marginRight: "auto",
        paddingBottom: "200px",
      },
      "& p, span": {
        color: "#3C465F",
      },
      "& h2, h3, h4, h1, h5, h6": {
        color: "#3C465F",
      },
    },
    headerTextHolder: {
      flexDirection: "column",
      marginBottom: "28px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    errorInputField: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,

        background: "#F7F5E7",
        "& input": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#3C465F !important",
        },
        "& fieldset": {
          border: "none",
        },
        "&:active fieldset": {
          // background: "#FFFFFF",
        },
        "&:hover fieldset": {
          // background: "#FFFFFF",
        },
        "&.Mui-focused fieldset": {
          // background: "#FFFFFF",
          boxShadow: "none",
        },
        "&.Mui-error fieldset": {
          color: "#C94277",
        },
      },
    },
    label: {
      color: "#3C465F",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    containerForPassword: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
      '& input': {
        WebkitBoxShadow: '0 0 0 30px #f7f5e7 inset !important',
        backgroundColor: '#F7F5E7',
        WebkitTextFillColor: '#3C465F'
      }
    },
    inputField: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "8px",
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: "4px",
      },
      '& input': {
        WebkitBoxShadow: '0 0 0 30px #f7f5e7 inset !important',
        backgroundColor: '#F7F5E7',
        WebkitTextFillColor: '#3C465F'
      }
    },
    textSpan: {
      marginTop: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3C465F",
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    loginButton: {
      height: "56px",
      background: "#FF0F5F",
      width: "100%",
      color: "#ffffff",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: "24px",
      textTransform: "uppercase",
      cursor: "pointer",
      border: '2px solid #FF0F5F'
    },
    formHolder: {
      width: "100%",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      [theme.breakpoints.up("md")]: {
        fontSize: "32px",
        lineHeight: "40px",
      },
    },
    passwordRecovery: {
      fontFamily: "Poppins",
      textDecoration: "none",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "uppercase",
      color: "#FFFFFF",
      cursor: "pointer",
    },
    passwordRecoveryContainer: {
      marginTop: "16px",
    },
    errorMessage: {
      color: "#C94277",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      alignSelf: "flex-end",
    },
    bodyContainer: {
      maxWidth: "1440px",
      display: "flex",
      justifyContent: "space-between",
    },
    imageNoPaddingBackground: {
      //here
      [theme.breakpoints.up("md")]: {
        minHeight: "500px",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${fullSizeLogin})`,
        backgroundSize: "contain",
      },
    },
    subFormContainer: {
      maxWidth: "100%",
      paddingLeft: "calc(16.66666666666667% + 15px)",
      paddingRight: "8.8888888888889%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
      },
    },
    textAlignCenter: {
      textAlign: "center",
      paddingTop: "1em",
    },
    noAccountLabel: {
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "300",
      color: "#3C465F",
    },
    signUpLabel: {
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "capitalize",
      fontWeight: 500,
      color: "#3C465F",
      marginLeft: ".45em",
    },
    forgotPasswordLabel: {
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "20px",
      textDecoration: "underline",
      fontWeight: 400,
      color: "#3C465F",
    },
    errorContainer: {
      padding: ".3em .3em .3em 0px",
    },
    errorImage: {
      display: "inline",
      verticalAlign: "middle",
    },
    errorLabel: {
      color: "#FF0F5F !important",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "12px",
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: ".5em",
      maxWidth: "90%",
    },
    buttonCTAHolder: {
      marginTop: "24px",
    },
  }));

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [failedAuthentication, setFailedAuthentication] = useState(false);
  const [token, setToken] = useState(null);
  const [errorMsgActive, setErrorMsgActive] = useState(false);
  const [showEmailConfirmedMessage, setShowEmailConfirmedMessage] =
    useState(false);
  const [showPasswordResetSuccessMessage, setShowPasswordResetSuccessMessage] =
    useState(false);

  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handlePasswordFieldClick = () => {
    if (failedAuthentication) {
      setErrorMsgActive(false);
    }
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (failedAuthentication) {
      setErrorMsgActive(true);
    }
  }, [failedAuthentication]);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      window.addEventListener("storage", onStorageUpdate);
      
      var emailAddress = queryString.parse(location.search).email;
      // emailAddress is URL encoded and consists of a comma separated list of ASCII characters;
      // in order to get the decoded email address we need to do decodeEmail(decodeURIComponent(emailAddress))
      
      var emailConfirmationToken = queryString.parse(location.search).token;

      if (emailAddress && emailConfirmationToken) {        
        var response = await confirmEmail(
          decodeEmail(decodeURIComponent(emailAddress)),
          decodeURIComponent(emailConfirmationToken)
        );

        if (response) {
          setShowEmailConfirmedMessage(true);          

          var brazeUserId = await getBrazeUserId(decodeEmail(emailAddress).toLowerCase());
          if (brazeUserId) {
            brazeUserId = brazeUserId.replace('"', '');
            brazeUserId = brazeUserId.replace('"', '');
          }

          //braze verify user
          try {

            //console.log("brazeUserId", brazeUserId)

            if ((brazeUserId != "") && (typeof brazeUserId !="null")) {
              window.visibleVerifiedAlt(brazeUserId);

              window.BrazePageView();

              
            }
            
          } catch (e) {
            console.log("visibleVerifiedAlt-err",e)
          }

          setValue("Email", decodeEmail(decodeURIComponent(emailAddress)));
        }
      }

      var passwordResetSuccessfully = queryString.parse(
        location.search
      ).passwordResetSuccessfully;

      if (emailAddress && passwordResetSuccessfully) {
        setShowPasswordResetSuccessMessage(true);

        setValue("Email", decodeEmail(decodeURIComponent(emailAddress)));
      }
    })();
  }, []);

  useEffect(() => {
    if (shouldRedirect) {
      var returnUrl = queryString.parse(location.search).returnUrl;

      if (returnUrl && returnUrl.length > 0) {
        window.location.href = decodeURIComponent(returnUrl);
      }
    }
  }, [shouldRedirect]);

  useEffect(() => {
    (async () => {
      if (token) {
        window.localStorage.setItem("isUserAuthenticated", "true");
        window.localStorage.setItem("token", token);

        // next ln ensures we set the forumUserId in local storage
        var userObject = await getUserObject(token);

        //verify the nodebb account so they can post
        var userId = window.localStorage.getItem("ForumUserId");
        var email = window.localStorage.getItem("Email");
        var verNode = await verifyNodebb(userId, email);

        var brazeId = userObject.BrazeId;

        var returnUrl = queryString.parse(location.search).returnUrl;

        if (returnUrl && returnUrl.length > 0) {
          var decodedUrl = decodeURIComponent(returnUrl);

          var redirectUrl = decodedUrl;

          if (brazeId) {
            if (decodedUrl.includes("?")) {
              redirectUrl = redirectUrl;
            } else {
              redirectUrl = redirectUrl;
            }

            //braze
            try {
              window.visibleLoginBraze(brazeId)


            } catch (e) {

            }
          }

          if (!userObject.EmailConfirmed) {
            redirectUrl = '/visible/verify-email';
          }

          window.location.href = redirectUrl;
        } else {
          var redirectUrl = "/visible/members/";

          if (brazeId) {
            redirectUrl = "/visible/members/";

            //braze
            try {
              window.visibleLoginBraze(brazeId)


            } catch (e) {

            }

          }

          if (!userObject.EmailConfirmed) {
            redirectUrl = '/visible/verify-email';
          }

          window.location.href = redirectUrl;
        }
      }
    })();
  }, [token]);

  var onStorageUpdate = (e) => {
    const { key, newValue } = e;

    if (key === "isUserAuthenticated") {
      var isAuthenticated = newValue === "true" ? true : false;

      if (isAuthenticated) {
        setShouldRedirect(true);
      }
    }
  };

  var verifyNodebb = async function (userID, email) {
    var raw = JSON.stringify({
      _uid: 1,
    });

    var url =
      process.env.GATSBY_NODEBB_URL +
      "v3/users/" +
      userID +
      "/emails/" +
      email +
      "/confirm";

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.NODEBB_MASTER_API_TOKEN
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
  };

  var getToken = async function (username, password) {
    var modelJson = {
      grant_type: "password",
      username: username,
      password: password,
    };
    var formBody = [];
    for (var property in modelJson) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(modelJson[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    var dashboardUrl = process.env.GATSBY_DASHBOARD_URL;

    return fetch(dashboardUrl + "/oauth/token", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      });
  };

  var onSubmit = async function (data) {
    var response = await getToken(data.Email, data.Password);

    if (response.access_token) {
      setFailedAuthentication(false);
      setToken(response.access_token);
    } else {
      setFailedAuthentication(true);
    }
  };

  var confirmEmail = async function (email, token) {
    var model = {
      Email: email,
      Token: token,
    };

    var result = await fetch(
      process.env.GATSBY_QUESTIONAPI + "/ConfirmEmail/ConfirmEmail",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(model),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      });

    if (result.Success) {
      return true;
    } else {
      return false;
    }
  };

  var getBrazeUserId = async function (email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "EMAIL": email
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    var brazeUserId = await fetch(process.env.BRAZE_SIGNUP_URL, requestOptions)
      .then(response => response.text())
      .then((responseJson) => {
        return responseJson;
      })
      .catch(error => console.log('reg error', error));

    return brazeUserId;
  }

  return (
    <Grid container item alignItems={"center"} justify={"space-between"}>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={11} md={10} className={classes.bodyContainer}>
        <Grid container xs={12} md={6} className={classes.formContainer}>
          <Grid className={classes.subFormContainer}>
            <Grid container item className={classes.headerTextHolder}>
              <Typography variant="h4" className={classes.title}>
                {showEmailConfirmedMessage
                  ? "Your email has been verified!"
                  : showPasswordResetSuccessMessage
                  ? "Your password has been successfully updated!"
                  : "Welcome Back"}
              </Typography>
              <span className={classes.textSpan}>Log in to your account</span>
            </Grid>
            <form className={classes.formHolder}>
              <div>
                <Grid>
                  <label className={classes.label}>Email</label>
                  <Controller
                    name="Email"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        className={classes.inputField}
                      />
                    )}
                  />
                </Grid>

                <Grid className={classes.containerForPassword}>
                  <label className={classes.label}>Password</label>
                  <Controller
                    name="Password"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        // error={failedAuthentication}
                        variant="outlined"
                        error={failedAuthentication}
                        // onClick={handlePasswordFieldClick}
                        InputProps={{
                          type: values.showPassword ? "text" : "password",
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              <IconButton
                                aria-label={"toggle password visibility"}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {false ? (
                                  <img src={ErrorIcon} />
                                ) : values.showPassword ? (
                                  <img src={VisibilityOn} />
                                ) : (
                                      <img src={VisibilityOff} />
                                    )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        className={
                          failedAuthentication
                            ? classes.errorInputField
                            : classes.inputField
                        }
                      />
                    )}
                  />
                  {failedAuthentication && (
                    // <label className={classes.errorMessage}>
                    // </label>

                    <div className={classes.errorContainer}>
                      <img src={VisibleErrorInfo} className={classes.errorImage} />
                      <span className={classes.errorLabel}>
                        Invalid login attempt
                      </span>
                    </div>
                  )}
                </Grid>
                {/* For future use: */}
                {/*<Grid className={classes.passwordRecoveryContainer}>*/}
                {/*  <a className={classes.passwordRecovery} src="#">Recover Password</a>*/}
                {/*</Grid>*/}
                <div className={classes.buttonCTAHolder}>
                  <CampusCTA pageType={"available courses"} customClickEvent={handleSubmit(onSubmit)} linkText={"Log In"} buttonType={"rectangular"} arrowIcon={false} newTab={false} autoHeight={false} variant={"large"} />
                </div>
                <Grid container alignItems="center" justify="center">
                  <Grid item xs={12} className={classes.textAlignCenter}>
                    <label className={classes.noAccountLabel}>
                      Don't have an account?
                    </label>
                    <a href="/visible/register" className={classes.signUpLabel}>
                      SIGN UP!
                    </a>
                  </Grid>
                  <Grid item xs={12} className={classes.textAlignCenter}>
                    <a
                      href="/visible/forgot-password"
                      className={classes.forgotPasswordLabel}
                    >
                      FORGOT YOUR PASSWORD?
                    </a>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid
            container
            item
            xs={0}
            sm={0}
            md={6}
            className={classes.imageNoPaddingBackground}
          ></Grid>
        </Hidden>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
    </Grid>
  );
};

export default LoginForm;
