import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import serializeToHtml from "../utilities/lexicalDeserializer";
import { useEffect, useState } from "react";
import { $createParagraphNode, $getRoot } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";

function DeserializationPlugin({ content, element, isTeaser, outputRef, isPopularConversation }) {
  const [editor] = useLexicalComposerContext();
  const [firstRender, setFirstRender] = useState(isTeaser ? true : false)


  useEffect(() => {
    setFirstRender(true)
    return () => setFirstRender(false)
  }, [])

  const addTargetBlank = (outputRef) => {

    const childNodes = outputRef.current.childNodes
    for (let i =0 ; i < childNodes.length; i++){
      if (childNodes[i].className === "editor-paragraph"){
        let nodeList = childNodes[i]
        for (let i = 0; i < nodeList.childNodes.length; i++){
          nodeList.childNodes[i].tagName === "A" ? nodeList.childNodes[i].target = "_blank" : null;
        }
      }
    }
  }

  const addData = async (editorState) => {

  
    if (isTeaser){
      outputRef.current.dangerouslySetInnerHTML = await serializeToHtml(editorState) as string
    }
    if (!isTeaser) {
      //console.log(outputRef)

      if (typeof outputRef !="undefined") {
        outputRef.current.innerHTML = await serializeToHtml(editorState) as string

        // Open lexical links in new tab
        addTargetBlank(outputRef)

        if (isPopularConversation) {
          outputRef.current.innerHTML = outputRef.current.textContent;
        }
      }

     
    }
    if (typeof outputRef != "undefined") {
      if (outputRef.current && outputRef.current.textContent.length >= 350 && isTeaser) {
        outputRef.current.textContent = outputRef.current.textContent.substring(0, 350) + "..."
      }
    }
  }


  const editorState = editor.getEditorState()

  editor.update(() => {

    const parser = new DOMParser();
    const dom = parser.parseFromString(content, "text/html");
    const nodes = $generateNodesFromDOM(editor, dom);

    const root = $getRoot();
    const paragraphNode = $createParagraphNode()
    paragraphNode.append(...nodes)
    root.append(paragraphNode)

    useEffect(() => {
      if (firstRender){
        addData(editorState)
      }
    }, [firstRender])
  });

  return null
}

export default DeserializationPlugin;
